import { findKey } from 'lodash/fp';
import * as moment from 'moment';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Router } from '@angular/router';

import { TelematicsPeriod, TelematicsData, ReportView } from 'src/app/fleet/models/telematics.interface';
import { Styles } from 'src/app/shared/models/loading-skeleton.interface';
import Chart, { ChartConfiguration, ChartItem, ChartTypeRegistry } from 'chart.js/auto';
import { ChargerDataTypes, VehicleDataTypes } from '../../models/asset-filters.enum';
import { AssetType } from '../../../maintenance/models/tabs.enum';
import { UserInfo } from '../../../core/services/auth/user.service';
import { round } from 'lodash';

enum GraphStyle {
  Line = 'line',
  Bar = 'bar',
}

@Component({
  selector: 'xos-miles-driven-widget',
  templateUrl: './miles-driven-widget.component.html',
  styleUrls: ['./miles-driven-widget.component.scss'],
})
export class MilesDrivenWidgetComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() isFreePlan: boolean = false;
  @Input() assetType: AssetType = AssetType.Vehicle;
  @Input() chartId: number = 0;
  @Input() borderColor: string = '#D58547';
  @Input() barColor1: string = '#88C66B';
  @Input() barColor2: string = '#000000';
  @Input() chartWidth: number = 1120;
  @Input() chartHeight: number = 200;
  @Input() view: ReportView = ReportView.Detailed;
  @Input() loading: boolean = true;
  @Input() telematics!: TelematicsData;
  @Input() periodType: TelematicsPeriod = TelematicsPeriod.Month;
  @Input() mode: number = 1;
  @Input() userInfo!: UserInfo | null;
  @Input() maxTicks: number = 9;
  @Input() showNonElectricWarning: boolean = false;
  readonly modes = [
    { mode: 1, field: 'TCOSavings', unit: 'Dollars', assetType: AssetType.Vehicle, graphStyle: GraphStyle.Line },
    { mode: 2, field: 'Odo', unit: 'Miles', assetType: AssetType.Vehicle, graphStyle: GraphStyle.Line },
    { mode: 3, field: 'CO2Savings', unit: 'Lbs', assetType: AssetType.Vehicle, graphStyle: GraphStyle.Line },
    { mode: 4, field: 'TimeInService', unit: 'Hours', assetType: AssetType.Vehicle, graphStyle: GraphStyle.Line },
    { mode: 5, field: ['SocCharged', 'SocUsed'], unit: '%', assetType: AssetType.Vehicle, graphStyle: GraphStyle.Bar },
    { mode: 6, field: ['EnergyCharged', 'EnergyUsed'], unit: 'kWh', assetType: AssetType.Vehicle, graphStyle: GraphStyle.Bar },
    { mode: 1, field: 'power', unit: 'kW', assetType: AssetType.Charger, graphStyle: GraphStyle.Line },
  ];
  loaderStyles: Partial<Styles> = {
    height: '300px',
    'margin-bottom.px': 0,
    'background-color': '#F3F2F1',
    'border-radius.px': 2,
  };
  chart!: Chart | undefined;
  currentMode: { mode: number; field: string | string[]; unit: string; graphStyle: GraphStyle } = this.modes[0];
  existingAssetType = AssetType;
  dataTypes = VehicleDataTypes;
  reportView = ReportView;

  @ViewChild('telematicsChart')
  private canvas: ElementRef = {} as ElementRef;

  constructor(private router: Router) {}

  ngOnDestroy(): void {
    this.destroyChart();
  }

  ngAfterViewInit(): void {
    this.destroyChart();
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.telematics?.currentValue) {
      this.view === ReportView.Detailed && (this.loading = false);
      this.destroyChart();
      this.initChart();
    }

    if (changes?.mode?.currentValue || changes?.assetType?.currentValue) {
      this.destroyChart();
      this.currentMode = this.modes.find(item => item.mode === this.mode && item.assetType === this.assetType) || this.currentMode;
      this.initChart();
    }
    if (changes?.view?.currentValue === ReportView.MilesDetailed) {
      this.loaderStyles = { ...this.loaderStyles, height: '200px' };
    }
  }

  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  get totalMiles(): number {
    return this.telematics?.dataPoints.reduce((prev, curr) => (curr?.Odo ? prev + curr.Odo : prev), 0) | 0;
  }

  get totalKwhCharged(): number {
    return this.telematics?.dataPoints.reduce((prev, curr) => (curr?.EnergyCharged ? prev + curr.EnergyCharged : prev), 0) | 0;
  }

  get totalKwhConsumed(): number {
    return this.telematics?.dataPoints.reduce((prev, curr) => (curr?.EnergyUsed ? prev + curr.EnergyUsed : prev), 0) | 0;
  }

  get co2Savings(): number {
    return this.telematics?.co2Savings | 0;
  }

  get tcoSavings(): number {
    return this.telematics?.tcoSavings | 0;
  }

  get avgSocUsed(): number {
    return this.telematics?.avgSocUsed | 0;
  }

  get socUsedTotal(): number {
    return this.telematics?.socUsedTotal | 0;
  }

  get socChargedTotal(): number {
    return this.telematics?.socChargedTotal | 0;
  }

  get avgSocCharged(): number {
    return this.telematics?.avgSocCharged | 0;
  }

  get timeInService(): number {
    return this.telematics?.timeInService | 0;
  }

  get totalPowerDispensed(): number {
    return this.telematics?.dataPoints.reduce((prev, curr) => (curr?.power ? prev + curr.power : prev), 0) | 0;
  }

  get isChartVisible(): boolean {
    return (
      !this.showNonElectricWarning &&
      !this.isNoData &&
      (this.isFreePlan || (!this.loading && this.telematics && this.telematics.dataPoints.length > 0))
    );
  }

  get isNoData() {
    const isNoDataPoints = this.telematics?.dataPoints?.length === 0 || this.telematics?.dataPoints?.length === undefined;
    const isModeMilesDriven = this.assetType === AssetType.Vehicle && this.currentMode.mode === VehicleDataTypes['Miles driven'];
    const isModeCO2Savings = this.assetType === AssetType.Vehicle && this.currentMode.mode === VehicleDataTypes['Emissions savings'];
    const isModeTcoSavings = this.assetType === AssetType.Vehicle && this.currentMode.mode === VehicleDataTypes['TCO savings'];
    const isModeSoc = this.assetType === AssetType.Vehicle && this.currentMode.mode === VehicleDataTypes.SOC;
    const isModeEnergy = this.assetType == AssetType.Vehicle && this.currentMode.mode === VehicleDataTypes.Energy;
    const isModeTimeInOperation = this.assetType === AssetType.Vehicle && this.currentMode.mode === VehicleDataTypes['Time in operation'];
    const isModeCharger = this.assetType === AssetType.Charger;

    return (
      !this.isFreePlan && // in free plan, return false to show the chart (free mode version)
      (isNoDataPoints || // no data points at all
        (isModeMilesDriven && this.totalMiles === 0) || // no total miles in miles mode
        (isModeCO2Savings && this.co2Savings === 0) || // no co2
        (isModeTcoSavings && this.tcoSavings === 0) || // no tco
        (isModeTimeInOperation && this.timeInService === 0) || // no time in service
        (isModeSoc && this.socUsedTotal === 0 && this.socChargedTotal === 0) || // no soc
        (isModeEnergy && this.totalKwhCharged === 0 && this.totalKwhConsumed === 0) || // no energy
        (isModeCharger && this.totalPowerDispensed === 0)) // no charger data
    );
  }

  openVehicles() {
    this.view === ReportView.Detailed && this.router.navigate(['/fleet']);
  }

  getLabels() {
    switch (this.periodType) {
      case TelematicsPeriod.Month:
        return this.telematics?.dataPoints.map(m => moment(m.dateTime).utc().format('MMM D'));
      case TelematicsPeriod.Year:
        return this.telematics?.dataPoints.map(m => moment(m.dateTime).utc().format('MMM D'));
      case TelematicsPeriod.Custom:
        return this.telematics?.dataPoints.map(m => moment(m.dateTime).utc().format('MMM D'));
      case TelematicsPeriod.CustomLong:
        return this.telematics?.dataPoints.map(m => moment(m.dateTime).utc().format('MMM YYYY'));
      default:
        return this.telematics?.dataPoints.map(m => moment(m.dateTime).utc().format('MMM YYYY'));
    }
  }

  private createGradient(canvas: ChartItem): any {
    //@ts-ignore
    const ctx = canvas.getContext('2d');
    let gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, this.assetType === AssetType.Vehicle ? 'rgba(213, 133, 71, 0.5)' : 'rgba(136, 198, 107, 0.5)');
    gradient.addColorStop(1, this.assetType === AssetType.Vehicle ? 'rgba(213, 133, 71, 0)' : 'rgba(136, 198, 107, 0)');
    return gradient;
  }

  private initChartDatasets(field: string | string[], graphStyle: GraphStyle, gradient: any): any {
    return graphStyle === GraphStyle.Bar
      ? [
          {
            //@ts-ignore
            data: this.telematics?.dataPoints.map(m => round(m[field[0]], 1)),
            pointRadius: 0,
            borderRadius: 2,
            fill: true,
            barPercentage: 1.0,
            categoryPercentage: 0.5,
            backgroundColor: this.barColor1,
          },
          {
            //@ts-ignore
            data: this.telematics?.dataPoints.map(m => round(m[field[1]], 1)),
            pointRadius: 0,
            borderRadius: 2,
            fill: true,
            barPercentage: 1.0,
            categoryPercentage: 0.5,
            backgroundColor: this.barColor2,
          },
        ]
      : [
          {
            //@ts-ignore
            data: this.telematics?.dataPoints.map(m => round(m[field], 1)),
            pointRadius: 0,
            fill: true,
            backgroundColor: gradient,
          },
        ];
  }

  private getChartOptions(labelUnits: string, gradient: any): any {
    return {
      elements: {
        bar: {
          backgroundColor: this.barColor2,
          borderWidth: 0,
        },
        line: {
          borderColor: this.borderColor,
          borderWidth: 1,
          backgroundColor: gradient,
        },
      },
      responsive: true,
      interaction: {
        mode: 'x',
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          mode: 'nearest',
          enabled: true,
          position: 'nearest',
          titleFont: {
            family: 'Montserrat, sans-serif',
            weight: 'bold',
            size: 12,
          },
          bodyFont: {
            family: 'Montserrat, sans-serif',
            weight: 'normal',
            size: 12,
          },
          footerFont: {
            family: 'Montserrat, sans-serif',
            weight: 'normal',
            size: 12,
          },
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += Intl.NumberFormat('en-us').format(context.parsed.y) + labelUnits;
              }
              return label;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: this.view === ReportView.MilesDetailed ? 5 : this.maxTicks,
            font: {
              family: 'Montserrat, sans-serif',
              weight: 'normal',
              size: 12,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            font: {
              family: 'Montserrat, sans-serif',
              weight: 'normal',
              size: 12,
            },
          },
          beginAtZero: true,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    };
  }

  initChart(): void {
    const canvas = this.canvas.nativeElement;
    if (!canvas) return;

    const getModeByAssetType = () => this.modes.find(el => el.assetType === this.assetType);

    const field = this.view === ReportView.Detailed ? 'Odo' : (this.currentMode || getModeByAssetType())?.field;
    const graphStyle = (this.currentMode || getModeByAssetType())?.graphStyle;

    const unit = (this.currentMode || getModeByAssetType())?.unit;
    const metricSystem = this.userInfo?.useMetricSystem ? ' kilometers' : ' miles';
    const milesSet = unit === 'Miles' ? metricSystem : unit;
    const labelUnits = this.view === ReportView.Detailed ? metricSystem : ' ' + milesSet;

    const gradient = this.createGradient(canvas);

    const datasets = this.initChartDatasets(field, graphStyle, gradient);

    const config: ChartConfiguration = {
      type: graphStyle as keyof ChartTypeRegistry,
      data: {
        labels: this.getLabels(),
        datasets: datasets,
      },
      options: this.getChartOptions(labelUnits, gradient),
    };

    this.chart = new Chart(canvas, config);
  }

  getType(mode: number = this.mode): string {
    const type = findKey(el => Number(el) === mode, this.assetType === AssetType.Vehicle ? VehicleDataTypes : ChargerDataTypes) || '';
    return type === 'Miles driven' ? (this.userInfo?.useMetricSystem ? 'Kilometers driven' : 'Miles driven') : type;
  }
}
